import styled from 'styled-components/macro'

const Page = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: hidden;
    align-self: center;
    max-width: 100%;
    overflow-x: hidden;
    direction: ${props => props.eng ? 'ltr' : 'rtl'};
`

export default Page

