import styled from 'styled-components/macro'
import Container from "./Container"
import Section from './Section'
import SectionHeader from "./SectionHeader"
import Spacer from "./Spacer"
import GridSpacer from "./GridSpacer"
import { device, spacing } from "../../style"
import { useViewport } from '../../../useViewport'

const Wrapper = styled(Section)`
    max-height: unset;
`

const ResponsiveContainer = styled(Container)`
    display: flex;
    flex-direction: row;
    @media ${device.tablet} {
        flex-direction: column;
    }    
`

const ContentSection = ({header, background, eng, children}) => {
    
    const { device } = useViewport()

    return (
        <Wrapper row center={device === 'tablet' || device === 'mobile'} background={background} eng={eng}>
            <Container width={(device === 'tablet' || device === 'mobile') ? 4 : 8} indent={1} eng={eng}>
                <ResponsiveContainer eng={eng}>
                    <Container width={(device === 'tablet' || device === 'mobile') ? 5 : 3} eng={eng}>
                        <SectionHeader header={header} eng={eng} centered={(device === 'tablet' || device === 'mobile')}/>
                    </Container>
                    {device === 'desktop' && (<GridSpacer width={1}/>)}
                    {(device === 'tablet' || device === 'mobile') && (<Spacer height={spacing.spacing10}/>)}
                    <Container width={4}>
                        {children}
                    </Container>
                </ResponsiveContainer> 
            </Container>
        </Wrapper>
    )
}

export default ContentSection