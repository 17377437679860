import { useState, useEffect } from 'react';
import { breakpoints } from './common/style';

function getViewport() {
  const { innerWidth: width, innerHeight: height } = window;
  const device = width <= breakpoints.mobile ? 'mobile' : (width <= breakpoints.tablet ? 'tablet' : 'desktop')
  return {
    width,
    height,
    device
  };
}

function useViewport() {
  const [windowDimensions, setWindowDimensions] = useState(getViewport());

  /*
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getViewport());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  */

  return windowDimensions;
}

export { useViewport }