import { Helmet } from 'react-helmet'
import SectionSpacer from '../../common/shared/layout/SectionSpacer'
import Page from '../../common/shared/layout/Page'
import IntroSlogen2 from './sections/IntroSlogen2'
import HomeSlogen from './sections/HomeSlogen'
import IntroGallery2 from './sections/IntroGallery2'
import Coding2 from './sections/Coding2'
import ContactUs2 from './sections/ContactUs2'
import Footer from './sections/Footer'
import Welcome2 from './sections/Welcome2'
import EducationalModel from './sections/EducationalModel'
import EducationalApproach from './sections/EducationalApproach'
import Container from '../../common/shared/layout/Container'
import Outdoor from './sections/Outdoor'
import About from './sections/About'
import Vision from './sections/Vision'
import Sustainability from './sections/Sustainability2'
import GreenAI from './sections/GreenAI'
import InventionsGallery from '../il-home/sections/InventionsGallery'
import FocusSection from '../../common/shared/layout/FocusSection'
import InterApproch from './sections/InterApproch'
import ProblemBased2 from './sections/ProblemBased2'
import Handson from './sections/Handson'
import Slogen from '../../common/shared/layout/SlogenSection'
import SingleDisplay from '../../common/shared/layout/SingleDisplay'
import Spacer from '../../common/shared/layout/Spacer'
import { spacing } from '../../common/style'
import AboutProjects from './sections/AboutProjects'
import ML from './sections/ML'
import Team from '../il-home/sections/Team'
import { useViewport } from '../../useViewport'

const HomeFake = () => {
    const { device } = useViewport()
    return (
        <Page eng={true}>
            <Helmet>
                <title>Comets: Our Story Unfolds in the Futures</title>
                <meta name="description" content="Our Story Unfolds in the Future"/>
            </Helmet>
            <Welcome2/>
                {
                    (device === 'mobile' || device == 'tablet') && (
                        <Container id="general">
                            <About customHeader={{}}/>
                            <SectionSpacer/>
                            <IntroGallery2/>
                            <SectionSpacer/>
                        </Container>
                    )
                }
                {
                    (device === 'desktop') && (
                        <Container  center width={12}>
                            <SectionSpacer id="general"/>
                            <IntroSlogen2/>
                            <SectionSpacer/>
                            <IntroGallery2/>
                            <SectionSpacer/>
                        </Container>
                    )
                } 
            <HomeSlogen/>
            <SectionSpacer/>
            <ProblemBased2/>
            <SectionSpacer/>
            <Coding2/>
            <Spacer height={spacing.spacing20}/>
            <SingleDisplay 
                image={'https://res.cloudinary.com/dfdk4g2pj/image/upload/v1720007106/Group_16_1_mqurji.png'} 
                imageMobile={'https://res.cloudinary.com/dfdk4g2pj/image/upload/v1720007204/Group_16_1_mqurji_c_crop_ar_1_1_ypeyx1.png'}
                width={9}/>  
            <SectionSpacer id="sustain"/>
            <FocusSection eng header={{
                topic: 'Sustainability',
                title: <span style={{fontWeight:'200'}}>
                    <span style={{fontWeight:'300'}}>
                    Reconnecting
                    </span>
                    {" "}
                    with the 
                    Environment
                </span>,
                desc: "Our innovative solutions bridge the gap between business operations and environmental stewardship. By transforming complex environmental data into clear, actionable insights, we help your company develop a deeper understanding of its ecological impact. This deeper awareness enables you to make informed choices that align your company's growth with nature's rhythms, fostering a harmonious relationship between profit and planet. We make environmental responsibility an integral part of your company's DNA, not just a corporate obligation."
            }}/>
            <SectionSpacer id="contact"/>
            <ContactUs2/>
            <Footer/>
        </Page>  
    )
}

export default HomeFake