import ImageSection from '../../../common/shared/layout/ImageSection'
import DiscoverButton from '../../../components/DiscoverButton'
import { GradiantText } from '../../../common/typography'
import { color, spacing } from '../../../common/style'
import { HashLink } from 'react-router-hash-link'
import Spacer from '../../../common/shared/layout/Spacer'
import { useViewport } from '../../../useViewport'

const image = 'https://res.cloudinary.com/dfdk4g2pj/image/upload/v1698184934/elirannatan_Agriculture_AI_-_driven_robotic_systems_could_trans_1b7af9c5-6811-4f8e-92d1-1fccfbca809b_q6zrzo.png'

const Welcome2 = () => {

    const { device } = useViewport()

    const header = {
        title: <span>
        <GradiantText color={color.blackGradient} style={{fontWeight:'100', fontSize: (device === 'desktop') ? '56px' : '48px'}}>
            Our Story Unfolds in the <span style={{fontWeight:'300'}}>Future</span>
         </GradiantText>
        </span>,
        desc:( device === 'mobile' || device === 'tablet') ? null : `
            Comets is a groundbreaking initiative at the forefront of sustainability innovation, dedicated to developing globally unique AI solutions.
         `,
         girly: true
    }

    return (
        <ImageSection image={image} header={header} first eng>
            {(device === 'desktop') && (
                <>
                <Spacer height={spacing.spacing6}/>
                <DiscoverButton eng/>
                </>
            )}
        </ImageSection>
    )
}

export default Welcome2
