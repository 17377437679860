export const fonts = {
    main: 'Assistant'
}

export const fontWeight = {
    extraLight: 200,
    light: 300,
    regular: 400,
    semiBold: 600,
    bold: 700,
    extraBold: 800
}

export const fontSize = {
    fontSize0: '8.19px',
    fontSize1: '10.24px',
    fontSize2: '12.80px',
    fontSize3: '16.00px',
    fontSize4: '20.00px',
    fontSize5: '25.00px',
    fontSize6: '31.25px',
    fontSize7: '39.06px',
    fontSize8: '48.83px',
    fontSize9: '61.04px',
    fontSize10: '76.29px',
}

export const color = {
    white: '#FFFFFF',
    black: '#121212',
    gray0: '#DEDEDE',
    gray1: '#F6F6F6',
    gray2: '#6e6e73',
    gray3: '#676767',
    blackGradient: 'linear-gradient(266.34deg, #1E1E1E 15.2%, rgba(31, 31, 31, 0.87) 95.82%)',
    cool: 'linear-gradient(270deg, #21D4FD 0%, #B721FF 100%)',
    summer: 'linear-gradient(147deg, #FFE53B 0%, #FF2525 35%)',
    spring: 'linear-gradient(37deg, #F85C66 0%, #8854FE 100%)',
    ocean: 'linear-gradient(91deg, #4FABF8 0%, #000AFF 100%)',
    green: 'linear-gradient(to top, #0ba360 0%, #3cba92 100%);',
    forest: 'linear-gradient(45deg, #45c84d 59%, #6fbeb7 65%, #0aada4 70%)',
}

export const spacing = {
    spacing0: '0',
    spacing1: '0.25rem',
    spacing2: '0.5rem',
    spacing3: '0.75rem',
    spacing4: '1rem',
    spacing5: '1.25rem',
    spacing6: '1.5rem',
    spacing8: '2rem',
    spacing10: '2.5rem',
    spacing12: '3rem',
    spacing16: '4rem',
    spacing20: '5rem',
    spacing24: '6rem',
    spacing32: '8rem',
    spacing36: '12rem',
    spacing64: '16rem',
}

export const breakpoints = {
    mobile: 414,
    tablet: 1024,
    desktopS: 1500
}

export const device = {
    mobile: `(max-width: ${breakpoints.mobile}px)`,
    tablet: `(max-width: ${breakpoints.tablet}px)`,
    desktopS: `(max-width: ${breakpoints.desktopS}px)`
}

export const TOPBAR_HEIGHT = 64;




