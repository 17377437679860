import { useEffect, useState } from 'react'
import styled, { keyframes } from "styled-components/macro"
import Spacer from '../common/shared/layout/Spacer'
import SingleSelect from '../common/shared/SingleSelect'
import UserDetailsForm from './UserDetailsForm'
import { fontSize, fontWeight, spacing, device } from '../common/style'
import { TextHeighlighted, SectionTitle } from '../common/typography'
import IconButton from '../common/shared/IconButton'
import Container from '../common/shared/layout/Container'

const STEPS = [
    {
        question: 'במה אתם מתעניינים?',
        description: 'בחרו את אחת האפשרויות המופיעות מטה',
        options: [
            {
                icon: '/shavitim-assets/swatches.svg',
                caption: ' באחת מתוכניות שביטים'
            },
            {
                icon: '/shavitim-assets/handshake.svg',
                caption: ' בשיתוף פעולה'
            },
            {
                icon: '/shavitim-assets/sparkle.svg',
                caption: ' בתכנית מותאמת אישית'
            },
            {
                icon: '/shavitim-assets/info.svg',
                caption: 'נושא אחר'
            }
        ]
    },
    {
        question: 'מהיכן אתם?',
        description: 'בחרו את אחת האפשרויות המופיעות מטה',
        options: [
            {
                icon: '/shavitim-assets/education.svg',
                caption: 'מוסד חינוך'
            },
            {
                icon: '/shavitim-assets/store.svg',
                caption: 'חברה פרטית'
            },
            {
                icon: '/shavitim-assets/bank.svg',
                caption: 'רשות או מועצה'
            },
            {
                icon: '/shavitim-assets/factory.svg',
                caption: 'אחר'
            }
        ]
    },
    {
        question: 'מלאו את פרטיכם',
        description: 'נציגי שביטים יצרו עמכם קשר בהקדם האפשרי.'
    },
    {
        question: 'אנו מודים לכם על התעניינותכם בתוכנית שביטים!',
        description: 'נציגי שביטים יצרו עמכם קשר בהקדם האפשרי.'
    }
]

const STEPS_ENG = [
    {
        question: 'What are you interested in?',
        description: 'Please select one of the options below',
        options: [
            {
                icon: '/shavitim-assets/swatches.svg',
                caption: 'One of Comets programs'
            },
            {
                icon: '/shavitim-assets/handshake.svg',
                caption: 'Collaboration'
            },
            {
                icon: '/shavitim-assets/sparkle.svg',
                caption: 'A customized program'
            },
            {
                icon: '/shavitim-assets/info.svg',
                caption: 'Other topics'
            }
        ]
    },
    {
        question: 'Where are you from?',
        description: 'Please select one of the options below',
        options: [
            {
                icon: '/shavitim-assets/education.svg',
                caption: 'Educational institution'
            },
            {
                icon: '/shavitim-assets/store.svg',
                caption: 'Private company'
            },
            {
                icon: '/shavitim-assets/bank.svg',
                caption: 'Authority or council'
            },
            {
                icon: '/shavitim-assets/factory.svg',
                caption: 'Other'
            }
        ]
    },
    {
        question: 'Please provide your details',
        description: 'A representative from Comets will contact you as soon as possible.'
    },
    {
        question: 'Thank you for your interest in the Comets program!',
        description: 'A representative from Comets will contact you as soon as possible.'
    }
]




const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
`

const StepContent = styled.div`
    display: ${props => props.show ? 'flex' : 'none'};
`

const StepWrapper = styled.div`
    width: 100%;
`

const QuestionHeader = styled.div`
    * {
        text-align: ${props => props.titleRigth ? 'right' : 'center'};
    }
`
const Question = styled.div`
    * {
        text-align: ${props => props.titleRigth ? 'right' : 'center'};
    }
`

const Note = styled.div`
    font-size: ${fontSize.fontSize5};
    font-weight: ${fontWeight.light};
    text-align: center;
`

const Description = styled.div`
    font-size: ${fontSize.fontSize4};
    font-weight: ${fontWeight.regular};    
`
const Answare = styled.div`
    display: flex;
    flex-direction: row;  
    justify-content: center;  
`
const Control = styled.div`
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-self: center;
    align-items: center;
    width: 100%;
    @media ${device.mobile} {
        flex-direction: column-reverse;
        justify-content: flex-start;
    }
`

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div`
    img {
        width: 25px;
        transform: invert(1);
        animation: ${rotate} 1.5s linear infinite; 
    }
`

const NextButtonPanel = styled(Container)`
    display: flex;
    * {
        border-radius: 10px!important;
    }
    @media ${device.mobile} {
        align-self: center;
        width: 100%;
        max-width: 100%;
    }
`

const PrevButtonPanel = styled.div`
    display: flex;
    justify-content: flex-start;
    visibility: ${props => props.show ? 'visible' : 'hidden'};
    @media ${device.mobile} {
        justify-content: center;
        width: 100%;
        max-width: 100%;
    }
`

const ResponsiveSpacer = styled(Spacer)`
    width: ${spacing.spacing6};
    @media ${device.mobile} {
        height: ${spacing.spacing6};
    }
`

const ResponsiveSpacer2 = styled(Spacer)`
    height: ${spacing.spacing10};
    @media ${device.mobile} {
        height: ${spacing.spacing20};
    }
`

const Step = ({question, children, titleRigth}) => {
    return (
        <StepWrapper>
            <Question titleRigth={titleRigth}>
                <TextHeighlighted>
                    {question}
                </TextHeighlighted>
            </Question>
            <Spacer height={spacing.spacing16}/>
            <Answare>
                {children}
            </Answare>
        </StepWrapper>        
    )
}

const SingleSelectStep = ({question, description, options, selectedIndex, onSelected, titleRigth}) => {
    return (
        <Step question={question} description={description} titleRigth={titleRigth}>
            <SingleSelect options={options} selectedIndex={selectedIndex} onSelected={onSelected}/>
        </Step>
    )
}

const UserDetailsStep = ({question, description, onUpdate, titleRigth, eng}) => {
    return (
        <Step question={question} description={description} titleRigth={titleRigth}>
            <UserDetailsForm onUpdate={onUpdate} eng={eng}/>
        </Step>
    )
}

const ContactForm = ({initialState, initialInquiryType, titleRigth, eng}) => {

    const steps = eng ? STEPS_ENG : STEPS

    const [step, setStep] = useState(initialState || 0)
    const [inquiryTypeSelectedIndex, setInquiryTypeSelectedIndex] = useState(initialInquiryType || null)
    const [inquiryUserTypeIndex, setInquiryUserTypeIndex] = useState()
    const [inquiryUserDetails, setInquiryUserDetails] = useState()
    const [nextButtonDisabled, setNextButtonDisabled] = useState(true)
    const [sendingInquiry, setSendingInquiry] = useState(false)
    
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(false)

    const sendInquiry = () => {
        fetch('https://shavitim-bot.vercel.app/api/contact-us', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                inquiryTypeId: inquiryTypeSelectedIndex,
                inquiryUserTypeId: inquiryUserTypeIndex,
                firstName: inquiryUserDetails.firstName,
                lastName: inquiryUserDetails.lastName,
                orgName: inquiryUserDetails.orgName,
                phone: inquiryUserDetails.phone,
                email: inquiryUserDetails.email,

            })
          }).then(res => {
            setError(false)
            setSuccess(true)
          }).catch(e => {
              setError(true)
              setSuccess(false)
          }).finally(() => {
            setSendingInquiry(false)
          })
    }

    useEffect(() => {
        if (step == 0) {
            setNextButtonDisabled(inquiryTypeSelectedIndex ===  0 ? inquiryTypeSelectedIndex : !inquiryTypeSelectedIndex)
        }
        if (step == 1) {
            setNextButtonDisabled(inquiryUserTypeIndex ===  0 ? inquiryUserTypeIndex : !inquiryUserTypeIndex)
        }
        if (step == 2) {
            setNextButtonDisabled(!inquiryUserDetails)
        }
    })

    const handleNext = () => {
        if (step === 2) {
            setSendingInquiry(true)
            sendInquiry()

        } else {
            setStep(step + 1)
        }
    }


    return (
        <Wrapper>
            {!success && !error && (
                <>
                    <StepContent show={step === 0}>
                        <SingleSelectStep {...steps[0]} selectedIndex={inquiryTypeSelectedIndex} titleRigth={titleRigth} onSelected={index => setInquiryTypeSelectedIndex(index)}/>
                    </StepContent>
                    <StepContent show={step === 1}>
                        <SingleSelectStep {...steps[1]} selectedIndex={inquiryUserTypeIndex} titleRigth={titleRigth} onSelected={index => setInquiryUserTypeIndex(index)}/>
                    </StepContent>
                    <StepContent show={step === 2}>
                        <UserDetailsStep eng={eng} {...steps[2]} titleRigth={titleRigth} onUpdate={userDetails => setInquiryUserDetails(userDetails)}/>
                    </StepContent>
                    <ResponsiveSpacer2/>
                    <Control>
                        <PrevButtonPanel show={step > 0}>
                        <IconButton noBorder={true} rotateIcon={eng} iconBefore={eng ? false : true} iconInvert icon={'/shavitim-assets/right_black.svg'} width={'50px'} onClick={() => setStep(step - 1)}>
                            {eng ? 'Previous' : 'הקודם'}
                        </IconButton>
                        </PrevButtonPanel> 
                        <ResponsiveSpacer/>                   
                        <NextButtonPanel width={4}>
                            <IconButton expanded={true} height={'69px'} iconBefore={eng} rotateIcon={eng} filled={true} darkMode={true} icon={!sendingInquiry ? '/shavitim-assets/left.svg' : null} disabled={nextButtonDisabled} width={'100%'} onClick={handleNext}>
                                {!sendingInquiry && step !== 2 && (eng ? 'Next' : 'הבא')}
                                {!sendingInquiry && step === 2 && (eng ? 'Send' : 'שלחו')}
                                {sendingInquiry && step === 2 && (
                                    <Spinner>
                                        <img src="/shavitim-assets/spinner.png"/>
                                    </Spinner>
                                )}
                            </IconButton>
                        </NextButtonPanel>
                    </Control>             
                </>
            )}
            {success && (
                <QuestionHeader>
                    <Question>
                        <SectionTitle eng={eng}>
                            {eng ? 'We thank you for your interest in Comets.' : 'אנו מודים לכם על ההתעניינות בתוכנית שביטים!'}
                        </SectionTitle>
                    </Question>
                    <Spacer height={spacing.spacing8}/>
                    <Description>
                        {eng ? 'Your request has been sent successfully. Comets representatives will contact you as soon as possible.' : 'בקשתכם נשלחה בהצלחה. נציגי שביטים יצרו עמכם קשר בהקדם האפשרי.'}
                    </Description>
                </QuestionHeader>
            )}      
            {error && (
                <QuestionHeader>
                    <Question>
                        {eng ? 'An error occurred while sending the request.' : 'התרחשה שגיאה בשליחת הבקשה.'}
                        התרחשה שגיאה בשליחת הבקשה. אנו מתנצלים על אי-הנוחות.
                    </Question>
                    <Spacer height={spacing.spacing2}/>
                    <Description>
                        {eng ? `We apologize for the inconvenience. Alternatively, you can contact us at support@Comets.com. Our representatives will get in touch with you as soon as possible.
Thank you for your interest in the Shavitim program!` : `אנו מתנצלים על אי-הנוחות. לחלופין, ניתן ליצור עימנו קשר בדוא״ל support@shavitim.com ונציגנו יצרו עמכם קשר בהקדם האפשרי. אנו מודים לכם על ההתעניינות בשביטים.`}
                        לחלופין, ניתן ליצור עימנו קשר באימייל support@shavitim.com. 
                        נציגנו יצרו עמכם קשר בהקדם האפשרי.
                        אנו מודים לכם על ההתעניינות בתוכנית שביטים!
                    </Description>
                </QuestionHeader>
            )}                       
        </Wrapper>
    )
}

export default ContactForm