import Container from "./Container"
import { Slogen as SlogenText } from '../../typography'

const Slogen = ({children}) => {
    return (
        <Container row center>
            <Container centeredText width={5} margins>
                <SlogenText>
                    {children}
                </SlogenText>
            </Container>
        </Container>
    )
}

export default Slogen