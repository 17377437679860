import styled from "styled-components/macro"
import FocusSection from "../../../common/shared/layout/FocusSection"
import { Link } from 'react-router-dom';
import Container from '../../../common/shared/layout/Container'
import { useViewport } from "../../../useViewport"
import { TextBody, TextMinor, TextMinorLight, TextHeighlighted } from "../../../common/typography"
import { spacing } from "../../../common/style"
import Spacer from "../../../common/shared/layout/Spacer"

const header = {
    topic: 'אודותנו',
    title: 'הכירו את צוות שביטים',
    desc: `
    אנחנו צוות מסור של אנשי חינוך, מומחי טכנולוגיה ומדריכים החולקים חזון משותף: להעצים את המוחות הצעירים עם הכלים והידע הדרוש לעיצוב העתיד. מתוך מחויבות לטפח סביבה שיתופית ומעשירה, אנו שואפים להצית סקרנות, לעודד יצירתיות ולהדריך את התלמידים במהלכו של מסע חינוכי חוצה גבולות.  
    `
}

const headerEng = {
    topic: 'Comets Team',
    title: 'Get to Know Us',
    desc: `
    We are a passionate team of educators, technology experts, and trainers united by a shared vision: empowering young minds with the essential tools and knowledge required to mold the future. Our unwavering commitment to nurturing a collaborative and enriching atmosphere drives us to kindle curiosity, foster creativity, and lead students on an educational journey that knows no bounds.
    `
}


const imageDesktop = "https://res.cloudinary.com/dfdk4g2pj/image/upload/v1699630318/elirannatan_Content_An_american_young_cute_smart_geek_multi-cal_6cafdacf-58ba-46ba-a02c-157d78b4f97b_anffsn.png"
const imageTablet = "https://res.cloudinary.com/dfdk4g2pj/image/upload/v1699630318/elirannatan_Content_An_american_young_cute_smart_geek_multi-cal_6cafdacf-58ba-46ba-a02c-157d78b4f97b_anffsn.png"

const eliranHeb = {
    name: 'אלירן נתן',
    title: 'מייסד, מרכיב תוכנית הלימודים',
    image: 'https://res.cloudinary.com/dfdk4g2pj/image/upload/v1686484160/118732079_10223558435893099_3741007992127305573_n_i30ygn.jpg',
    desc: `    ארכיטקט תוכנה ב- Wix.
    בוגר הפקולטה למדעי המחשב בטכניון וסטודנט לתואר שני מחקרי בפילוסופיה באונ׳ תל-אביב.
    `,
    linkedin: 'https://www.linkedin.com/in/enatandev/'
}

const eliranEng = {
    name: 'Eliran Natan',
    title: 'Founder',
    image: 'https://res.cloudinary.com/dfdk4g2pj/image/upload/v1686484160/118732079_10223558435893099_3741007992127305573_n_i30ygn.jpg',
    desc: `    Software Architect at Wix. Technion Computer Science graduate; Philosophy MA student at Tel Aviv University.`,
    linkedin: 'https://www.linkedin.com/in/enatandev/'
}

const noaHeb = {
    name: 'נועה מלר',
    title: 'ארט-דירקטור',
    image: 'https://res.cloudinary.com/dfdk4g2pj/image/upload/v1689274114/1686386684251_enpdz9.jpg',
    desc: `
    מומחית לאיפיון חוויית משתמש
    של פלטפורמות חינוכיות דיגיטליות.
    בוגרת המגמה לתקשורת חזותית בשנקר.
    `,
    linkedin: 'https://www.linkedin.com/in/noa-maller/'
}

const noaEng = {
    name: 'Noa Maller',
    title: 'Product Designer',
    image: 'https://res.cloudinary.com/dfdk4g2pj/image/upload/v1689274114/1686386684251_enpdz9.jpg',
    desc: `
    Specialist in User Experience Design
    for digital educational platforms.
    Graduate of the Visual Communication Program at Shenkar College.`,
        linkedin: 'https://www.linkedin.com/in/noa-maller/'
}

const Image = styled.img`
    width: 100%;
    align-self: center;
`

const HostBox = styled(Container)`
    align-items: flex-start;
`

const Host = ({name, title, image, desc, linkedin}) => {

    const HostAvatar = styled.div`
        img {
            border-radius: 100%;
            width: 200px;
            height: 200px;
        }
    `

    return (
        <Container column center>
            <Container column center centeredText>
                <HostAvatar>
                    <img src={image}/>
                </HostAvatar>
                <Spacer height={spacing.spacing8}/>
                <Container>
                    <TextHeighlighted>
                        <div style={{display:'flex', justifyContent: 'center', gap: '10px', alignItems:'center'}}>
                            <div style={{display:'flex', justifyContent: 'center', gap: '10px', alignItems:'center'}}>
                                {name}
                            </div>
                            {linkedin && (
                                <Link to={linkedin} target="_blank">
                                    <div style={{display:'flex', justifyContent: 'center', gap: '10px', alignItems:'center'}}>
                                        <img src="/shavitim-assets/logo-linkedin-logo-icon-png-svg.png" style={{width: '30px', paddingTop: '4px'}}/>
                                    </div>
                                </Link>
                            )}
                        </div>
                    </TextHeighlighted>
                </Container>
                <Spacer height={spacing.spacing2}/>
                <Container>
                    <TextBody>
                        {title}
                    </TextBody>
                </Container>
            </Container>
            <Spacer height={spacing.spacing2}/>
            <Container center centeredText>
                <TextMinorLight>
                    {desc}
                </TextMinorLight>
            </Container>
        </Container>        
    )
}

const Team = ({eng}) => {

    const {device, width} = useViewport()

    const eliran = eng ? eliranEng : eliranHeb
    const noa = eng ? noaEng : noaHeb
    
    return (
        <FocusSection header={eng ? headerEng : header} eng={eng}>
            <Container column center>
                {width > 689 ? (
                <Container row center>
                    <Container center row width={device === 'desktop' ? 7 : (device==='tablet' ? 4 : 4)}>
                        <HostBox row center width={2}>
                            <Host {...eliran}/>
                        </HostBox>
                        <Container row center width={1}/>
                        <HostBox row center width={2}>
                            <Host {...noa}/>
                        </HostBox>
                    </Container>
                </Container>
                ) : (
                <Container row center>
                    <Container center column width={4}>
                        <Container row center>
                            <Host {...eliran}/>
                        </Container>
                        <Spacer height={spacing.spacing24}/>
                        <Container row center>
                            <Host {...noa}/>
                        </Container>
                    </Container>
                </Container>              
                )}
                {false && <>
                    <Spacer height={spacing.spacing24}/>
                    <Container row center>
                        <Container width={11}>
                            <Image src={device == 'desktop' ? imageDesktop : imageTablet}/>
                        </Container>
                    </Container>
                </>}
            </Container>
        </FocusSection>
    )
}

export default Team