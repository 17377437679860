import AboutSection from '../../../components/AboutSection'

const About = ({customHeader}) => {

    const header = {
        topic: 'About Us',
        title: <span style={{fontWeight:'100'}}>Welcome to <span style={{fontWeight:'300'}}>Comets</span></span>,
        desc: 'At Comets, we believe in revolutionizing education to foster a brighter, more intelligent tomorrow.'
    }

    return (
        <AboutSection header={customHeader ? customHeader : header} eng={true}>
            Comets is a groundbreaking initiative at the forefront of educational innovation, dedicated to developing globally unique AI programs. By integrating cutting-edge technology and forward-thinking methodologies, Comets aims to cultivate the next generation of leaders and problem-solvers. Our programs are designed to equip students with the critical skills necessary to harness the power of AI, addressing complex global challenges and driving progress across various industries and communities worldwide.
            <br></br><br></br>
            Through our comprehensive, end-to-end training solutions, Comets empowers educators to implement state-of-the-art AI curricula effectively. Our innovative curriculum combines an interdisciplinary approach, problem-based learning, and hands-on experience, ensuring that both educators and students are well-prepared for the demands of an AI-driven future. 
        </AboutSection>
    )
}

export default About