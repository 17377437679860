import SlogenSection from '../../../common/shared/layout/SlogenSection'

const content = {
    slogen: `Comets delivers world-class AI solutions, revolutionizing sustainability.`
}

const IntroSlogen2 = () => {
    return (
        <SlogenSection>
            {content.slogen}
        </SlogenSection>
    )
}

export default IntroSlogen2