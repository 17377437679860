import ImageSection from '../../../common/shared/layout/ImageSection'

const image = 'https://res.cloudinary.com/dfdk4g2pj/image/upload/v1719846668/elirannatan_3d_model_minimal_wireframes_on_a_black_white_ai_vis_c1914ce0-620d-46f8-8684-ff57791e4762_znvrw1.png'

const ProblemBased2 = () => {
    const header = {
        
        title: <span style={{fontWeight:'200'}}>
           Understanding the World Through the {" "}
           <span style={{fontWeight:'300'}}>
           Data Lens
           </span>
        </span>,
        desc: <span>
In the rapidly evolving Big Data era, data has become the lifeblood of AI systems. We believe that cultivating a novel, data-driven perspective is crucial for spearheading the technological landscape of tomorrow. 
<br/><br/>
Our cutting-edge solutions transform complex operational data into actionable sustainability insights, enabling businesses to make informed decisions that benefit both their bottom line and the planet. By harnessing the power of AI, we help organizations optimize resource consumption, reduce environmental impact, and drive meaningful change across their operations. 
        </span>        
    }
    
    return (
        <ImageSection eng={true} image={image} header={header}/>
    )
}

export default ProblemBased2
