import { useEffect, useState } from "react"
import styled from "styled-components/macro"
import Spacer from "../common/shared/layout/Spacer"
import InputBox from '../common/shared/InputBox'
import { spacing } from "../common/style"

const Wrapper = styled.div`
    width: 100%;
`

const InputRow = styled.div``
const ErrorLabel = styled.div`
    text-align: ${props => props.eng ? 'left' : 'right'};
`

const UserDetailsForm = ({onUpdate, eng}) => {

    const [firstName, setFirstName] = useState()
    const [lastName, setLastName] = useState('deprecated')
    const [orgName, setOrgName] = useState()
    const [phone, setPhone] = useState()
    const [email, setEmail] = useState()

    const [emailError, setEmailError] = useState()

    const validateEmail = (email) => {
        return String(email)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
      };

    useEffect(() => {
        if (!(!validateEmail(email) || !firstName || !lastName || !orgName || !phone)) {
            onUpdate({
                firstName,
                lastName,
                orgName,
                phone,
                email
            })
        } else {
            onUpdate(null)
        }
    }, [firstName, lastName, orgName, phone, email])

    const handleEmailBlur = (email) => {
        if (!validateEmail(email)) {
            setEmailError(true)
        } else {
            setEmailError(false)
        }
    }

    const handleEmail = value => {
        setEmail(value)
        if (validateEmail(value)) {
            setEmailError(false)
        }
    }

    return (
        <Wrapper>
            <InputRow>
                <InputBox eng={eng} placeholder={eng ? 'Full Name' : 'שם מלא'} onInput={e => setFirstName(e.target.value)}/>
            </InputRow>
            <Spacer height={spacing.spacing6}/>
            <InputRow>
                <InputBox eng={eng} placeholder={eng ? 'Name of organization' : 'שם הארגון'} onInput={e => setOrgName(e.target.value)}/>
            </InputRow>
            <Spacer height={spacing.spacing6}/>
            <InputRow>
                <InputBox eng={eng} placeholder={eng ? 'Phone number' : 'מספר הטלפון'} onInput={e => setPhone(e.target.value)}/>
            </InputRow>
            <Spacer height={spacing.spacing6}/>
            <InputRow>
                <InputBox eng={eng} placeholder={eng ? 'Email address' : 'כתובת אימייל'} onInput={e => handleEmail(e.target.value)} onBlur={e => handleEmailBlur(e.target.value)}/>
                <Spacer height={spacing.spacing3}/>
                {emailError && <ErrorLabel eng={eng}>{eng ? 'Email address is illegal' : 'כתובת האימייל אינה חוקית'}</ErrorLabel>}
            </InputRow>
        </Wrapper>
    )
}

export default UserDetailsForm