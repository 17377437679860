import styled from "styled-components/macro";
import { fontSize, color, fontWeight } from '../style';

const Button = styled.button`
    font-size: ${fontSize.small};
    letter-spacing: 0.1em;
    font-size: ${fontSize.fontSize3};
    font-weight: ${fontWeight.regular};
    letter-spacing: 0.1em;
    height: ${props => props.height ? props.height : '47px'};
    line-height: 0;
    width: ${props => props.expanded ? '100%' : 'fit-content'};
    padding-left: ${props => props.noPadding ? null : '20px'};
    padding-right: ${props => props.noPadding ? null : '20px'};
    text-align: center!important;
    opacity: ${props => props.disabled ? '0.7' : '1'};
    color: ${props => {
        if (props.filled) {
            return color.white
        } else {
            if (props.darkMode) {
                return color.white
            } else {
                return color.black
            }
        }
    }}!important;
    * {
        color: ${props => {
            if (props.filled) {
                return color.white
            } else {
                if (props.darkMode) {
                    return color.white
                } else {
                    return color.black
                }
            }
        }}!important; 
    }
    border: ${props => {
        if (props.noBorder) {
            return 'none'
        } else if (props.darkMode) {
            return `1px solid ${color.white}`
        } else {
            return `1px solid ${color.black}`
        }
    }};
    background: ${props => {
        if (props.filled) {
            return 'linear-gradient(96.53deg, #000000 31.58%, #222222 70.45%)'
        } else {
            return 'transparent'
        }
    }};
    outline: none;
    cursor: ${props => props.disabled ? 'default' : 'pointer'};
    border-radius: 40px;
`

export default Button