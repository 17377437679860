import styled from 'styled-components/macro'
import routes from '../../../utils/routes';
import Spacer from '../../../common/shared/layout/Spacer';
import { color, spacing, device, fontSize } from '../../../common/style'
import { TextSmall } from '../../../common/typography'
import Social from '../../../common/shared/Social'
import Container from '../../../common/shared/layout/Container'
import GeneralSection from '../../../common/shared/layout/GeneralSection';

const Main = styled.div`
    width: 33.33333332%;
    @media ${device.tablet} {
        width: 100%;
    }
`
const Logo = styled.div`
    display: flex;
    @media ${device.tablet} {
        justify-content: center;
    }
`
const LogoFigure = styled.img`
    width: 150px;
    margin-top: -8px;
    margin-right: -13px;
    @media ${device.tablet} {
        margin-right: 18px;
    }
`

const Copyrights = styled.div`
    padding-top: ${spacing.spacing2};
    text-align: right;
    color: gray;
    font-size: 10px;
    p {
        color: gray!important;
    }
    @media ${device.mobile} {
        text-align: center;
    }
`

const FooterWrapper = styled(Container)`
    align-items: center;
`

const SocialContainer = styled(Container)`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    @media ${device.tablet} {
        justify-content: center;
    }
`

const social = (
    <SocialContainer>
         <Social/>
    </SocialContainer>
)

const Footer = () => {
    const currentYear = new Date().getFullYear();
    return (
        <FooterWrapper column center background={color.black}>
            <Spacer height={spacing.spacing24}/>
            <GeneralSection contentLeft={social}>
                <Main>
                    <a href={routes.home}>
                        <Logo>
                            <LogoFigure src="/shavitim-assets/logo.svg"/>
                        </Logo>
                    </a>
                    <Copyrights>
                        <TextSmall>
                            {`${currentYear} © כל הזכויות שמורות.`}
                        </TextSmall>
                    </Copyrights>   
                </Main>
            </GeneralSection>
            <Spacer height={spacing.spacing16}/>
        </FooterWrapper>
    )
}

export default Footer