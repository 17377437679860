import { HashLink } from 'react-router-hash-link'
import Button from '../common/shared/Button'
import Container from '../common/shared/layout/Container'
import { useViewport } from '../useViewport'

const DiscoverButton = ({eng, spacial, link}) => {
    const { device } = useViewport()
    const caption = spacial ? spacial : (eng ? 'Discover More' : 'גלו את התוכנית')
    return (
        <Container width={device === 'desktop' ? '2' : (device === 'tablet' ? '2' : '4')}>
            <HashLink to={"#general"} style={{
                textAlign: eng ? 'left' : 'right'
            }}>
                <Button filled>
                    {caption}
                </Button>
            </HashLink>
        </Container>        
    )
}

export default DiscoverButton