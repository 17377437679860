import Gallery from '../../../components/Gallery'

const images = [
    'https://res.cloudinary.com/dfdk4g2pj/image/upload/v1719912298/elirannatan_Satellite_photography_of_wind_turbines_farm_--ar_16_b590efb9-3253-4b22-ae77-104502ea0645_afyr24_c_crop_ar_1_1_lwph9i.png',
    'https://res.cloudinary.com/dfdk4g2pj/image/upload/v1719846801/elirannatan_a_realistic_photography_of_an_arial_view_of_a_fie_a8721869-3c12-4856-ad4a-3e98ba243448_0_yj43au.png'
]

const IntroGallery2 = () => {
    return (
        <Gallery images={images} twoOnMobile/>
    )
}

export default IntroGallery2