import styled from 'styled-components/macro'
import { color, device, spacing } from '../../style'
import Spacer from './Spacer'

const ResponsiveSpacer = styled(Spacer)`
    width: ${spacing.spacing12};
    @media ${device.tablet} {
        width: ${spacing.spacing5};
    }
    @media ${device.mobile} {
        width: ${spacing.spacing4};
    }
`

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    @media ${device.tablet} {
        justify-content: space-between;
    }
`
const Box = styled.div`
    width: calc(50% - 16px);
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-self: center;
    background-color: ${props => props.background ? props.background : color.gray1};
    img {
        width: 100%;
    }
    @media ${device.tablet} {
        width: 50%;
    }
`

const DoubleDisplay = ({elm1, elm2, background}) => {
    return (
        <Wrapper>
            <Box background={background}>
                {elm1}
            </Box>
            <ResponsiveSpacer/>
            <Box background={background}>
                {elm2}
            </Box>
        </Wrapper>
    )
}

export default DoubleDisplay