import styled from 'styled-components/macro'
import { color, spacing } from '../../style'
import Spacer from './Spacer'
import { device } from '../../style'

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    @media ${device.mobile} {
        flex-direction: column;
    }
`
const Box = styled.div`
    width: ${props => props.num === 3 ? 'calc(33.3333% - 16px)' : (props.num === 2 ? 'calc(50% - 32px)' : '100%')};
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-self: center;
    background-color: ${color.lightGray};
    img {
        width: 100%;
    }
    @media ${device.mobile} {
        width: 100%;
    }
`

const ResponsiveSpacer = styled(Spacer)`
    width: ${spacing.spacing5};
    height: 0px;
    @media ${device.mobile} {
        height: ${spacing.spacing4};
        width: 0px;
    }
`

const TrioDisplay = ({images}) => {
    const num = images.length; 
    return (
        <Wrapper>
            {images.map((image,index) => {
                return (
                    <>
                        <Box num={num}>
                            <img src={image}/>
                        </Box>
                        {index < num-1 && (<ResponsiveSpacer/> )}                  
                    </>
                )
            })}
        </Wrapper>
    )
}

export default TrioDisplay