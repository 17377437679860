import styled from "styled-components/macro"
import Spacer from "./layout/Spacer"
import { spacing, color, fontSize, device } from "../style"
import GradientBox from './GradientBox'
import { TextBody } from "../typography"

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`

const IconPanel = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img {
        width: 24px;
    }
`

const OptionWrapper = styled.div`
    cursor: pointer;
    height: 71px;
    * {
        text-align: right!important;
    }
`

const Option = ({icon, caption, selected, onClick}) => {
    return (
        <OptionWrapper onClick={onClick}>
            <GradientBox borderRadius={10} borderWidth={1} background={color.gray1} backgroundOnHover={'#eaeaea'} color={selected ? color.spring : 'transparent'}>
                <IconPanel>
                    <img src={icon}/>
                </IconPanel>
                <Spacer width={spacing.spacing6}/>
                <TextBody>
                    {caption}
                </TextBody>
            </GradientBox>
        </OptionWrapper>        
    )
}

const SingleSelect = ({options, selectedIndex, onSelected}) => {

    const handleSelect = (index) => {
        onSelected(index)
    }

    return (
        <Wrapper>
            {options.map((option, index) => {
                return (
                    <div key={index}>
                        <Option key={index} icon={option.icon} caption={option.caption} selected={index === selectedIndex} onClick={() => handleSelect(index)}/>
                        {index < options.length-1 && <Spacer height={spacing.spacing6}/>}
                    </div>
                )
            })}
        </Wrapper>
    )
}

export default SingleSelect