import { useEffect, useState, useRef } from 'react'
import styled from "styled-components/macro"
import { spacing, TOPBAR_HEIGHT, fontWeight, fontSize, device } from '../style'
import { TextBody } from '../typography'
import routes from '../../utils/routes'
import { Link } from "react-router-dom"

import Container from './layout/Container'
import Spacer from './layout/Spacer'
import { HashLink } from 'react-router-hash-link'

const MOBILE_BREAKPOINT = 1000;

const Wrapper = styled(Container)`
    position: absolute; 
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: ${TOPBAR_HEIGHT}px;
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    z-index: 999999999;
    padding: ${spacing.spacing2} ${spacing.spacing24};
    background-color: #000000;
    @media (max-width: ${MOBILE_BREAKPOINT}px) {
        padding: ${spacing.spacing2} ${spacing.spacing8};
        overflow: hidden;
        flex-direction: column;
        justify-content: flex-start;
    }
`

const DropdownMenuWrapper = styled(Container)`
    width: 100%; 
    max-height: ${props => !props.isOpen ? `0` : '800px'};
    transition: max-height 0.55s; 
    position: absolute;
    top: 60px;
    z-index: 9999999;
    overflow: hidden;
    background-color: #000000;
    padding: 0px ${spacing.spacing24};
`

const DropdownMenu = styled(Container)`
    display: flex
    width: 100%;
    transition: max-height 0.55s;
    height: fit-content;
    padding: ${spacing.spacing16} 0px;
    flex-direction: ${props => props.eng ? 'row-reverse' : 'row'};
    @media (max-width: 721px) {
        flex-direction: column!important;
    }
`

const Inner = styled(Container)`
    display: flex;
    flex-direction: ${props => props.eng ? 'row-reverse' : 'row'};
    justify-content: space-between;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    height: 45px;
    @media (max-width: ${MOBILE_BREAKPOINT}px) {
        flex-direction: ${props => props.eng ? 'row' : 'row-reverse'};
    }
`

const LogoHeb = styled.div`
    position: relative;
    cursor: pointer;
    img {
        width: 120px;
        margin-bottom: -6px;
    }
    @media (max-width: ${MOBILE_BREAKPOINT}px) {
        img {
            width: 100px;
            margin-left: -20px;
        }
    }  
`

const LogoEng = styled.div`
    cursor: pointer;
    display: flex;
`

const MobileMenuIcon = styled.div`
    display: none;
    @media (max-width: ${MOBILE_BREAKPOINT}px) {
        display: flex;
        justify-content: center;
        flex-direction: column;
        img {
            width: 21px;
            margin-top: 4px;
            transform: ${props => !props.eng ? 'rotateY(180deg)' : null};
        }
    }
`

const DropdownCol = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${spacing.spacing12};
`

const DropdownMenuInner = styled.div`
    display: flex;
    flex-direction: ${props => props.eng ? 'row-reverse': 'row'};
    width: 100%;
    gap: ${spacing.spacing24};
    @media (max-width: ${MOBILE_BREAKPOINT}px) {
        flex-direction: column;
        gap: ${spacing.spacing12};
    }
`

const DesktopTopBarRightSide = styled.div`
    display: flex;
    flex-direction: ${props => props.eng ? 'row-reverse' : 'row'};
    @media (max-width: ${MOBILE_BREAKPOINT}px) {
        display: none;
    }
`

const DesktopTopBarLeftLinks = styled.div`
    display: flex;
    @media (max-width: ${MOBILE_BREAKPOINT}px) {
        display: none;
    }    
`

const TopBarLinksWrapper = styled.nav`
    display: flex;
    justify-content: center;
    flex-direction: ${props => props.eng ? 'row-reverse' : 'row'};
    gap: ${spacing.spacing12};
    align-items: center;
    @media (max-width: ${MOBILE_BREAKPOINT}px) {
        flex-direction: column;
    }  
`

const MobileTopBarLeft = styled.div`
    display: none;
    @media (max-width: ${MOBILE_BREAKPOINT}px) {
        display: flex;
    } 
`

const goTo = (path) => {
    window.location.href = path;
}

const TopBarFake = ({eng}) => {

    const [menuIsOpen, setMenuIsOpen] = useState(false)
    const [isClicked, setIsClicked] = useState(false)

    const [show, setShow] = useState(true);
    let lastScrollY = window.scrollY;
  
    const controlNavbar = () => {
      if (window.scrollY > lastScrollY) {
        // If scroll down
        setShow(false);
      } else {
        // If scroll up
        setShow(true);
      }
      lastScrollY = window.scrollY;
    };
  
    useEffect(() => {
      window.addEventListener('scroll', controlNavbar);
      return () => {
        window.removeEventListener('scroll', controlNavbar);
      };
    }, [lastScrollY]);

    const openMenu = () => setMenuIsOpen(true)
    const closeMenu = () => setMenuIsOpen(false)

    const MenuTitle = styled.div`
        font-size: ${fontSize.fontSize3};
        font-weight: ${fontWeight.normal};
        opacity: 0.6;
        line-height: 28.8px;
        letter-spacing: 2px;
        transition: all 0.5s;
        cursor: pointer;
        display: ${props => props.onMobileOnly ? 'block' : 'block'};
        &:hover {
            opacity: 1;
        }
        @media (max-width: ${MOBILE_BREAKPOINT}px) {
            display: ${props => props.onMobileOnly ? 'block' : 'block'};
        } 
    `

    const handleMenuItemClick = () => {
        setIsClicked(true)
        setTimeout(() => {
            setIsClicked(false)
        }, 500)
        closeMenu()
    }

    const handleMouseOver = () => {
        if (isClicked) {
            closeMenu()
        } else {
            openMenu()
        }
    }

    const logoPanel = (
        <div style={{display:'flex', justifyContent: 'center', flexDirection: 'column'}}>
            {eng ? (
                <LogoEng onClick={() => goTo(routes.home)}>
                    <span style={{
                        textTransform: 'uppercase',
                        letterSpacing: '15px',
                        fontWeight: '300',
                        textAlign: 'center'
                    }}>
                        comets
                    </span>
                </LogoEng>
            ) : (
                <LogoHeb onClick={() => goTo(routes.home)}>
                    <img src={"/shavitim-assets/logo.svg"}/>
                </LogoHeb>
            )}
        </div>
    )

    const NavLink = ({to, onMobileOnly, children}) => (
        <MenuTitle onMobileOnly={onMobileOnly}>
            <HashLink scroll={(el) => el.scrollIntoView({ behavior: 'instant', block: 'start' })} to={to} onClick={handleMenuItemClick}>
                {children} 
            </HashLink>
        </MenuTitle>
    )

    const links = (
        <TopBarLinksWrapper eng={eng}>
                <Link to={'/ecosphere'}>
                    {eng ? 'EcoSphere' : 'כללי'}
                </Link>
        </TopBarLinksWrapper>
    )

    const endLinks = (
        <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'row', gap: spacing.spacing10, alignItems: 'center'}}>
            <NavLink to={'#contact'}>
                {eng ? 'Contact' : 'צרו קשר'}
            </NavLink>
            {
                !eng && (
                    <Link to={'https://www.wearecomets.com'} target='_blank'>
                        <img src="./shavitim-assets/globe.svg" style={{width:'20px', marginBottom:'-4px'}}/>
                    </Link>
                )
            }
        </div>        
    )

    const mobileMenuButton = (
        <MobileMenuIcon onClick={() => setMenuIsOpen(!menuIsOpen)} eng={eng}>
            <img src={menuIsOpen ? "/shavitim-assets/close-white.svg" : "/shavitim-assets/menu.svg"}/>
        </MobileMenuIcon>
    )

    return (
        <header className={`navbar ${show ? 'navbar-show' : 'navbar-hide'}`}>
            <Wrapper row center isOpen={menuIsOpen}>
                <Inner width={9} darkMode={true} eng={eng}>
                    <div style={{display: 'flex'}}>
                        {mobileMenuButton}
                        <DesktopTopBarRightSide eng={eng} style={{gap: spacing.spacing12}}>
                            {logoPanel}
                            {links}
                        </DesktopTopBarRightSide>
                    </div>
                    <div style={{display: 'flex'}}>
                        <DesktopTopBarLeftLinks style={{gap: spacing.spacing12}}>
                            {endLinks}
                        </DesktopTopBarLeftLinks>  
                        <MobileTopBarLeft>
                            {logoPanel}
                        </MobileTopBarLeft>                  
                    </div>

                </Inner>
            </Wrapper>
            <DropdownMenuWrapper row center isOpen={menuIsOpen} onMouseOver={handleMouseOver} onMouseLeave={closeMenu}>
                <DropdownMenu width={9} darkMode={true} eng={eng}>
                    <DropdownMenuInner eng={eng}>
                        <DropdownCol>
                            {links}
                        </DropdownCol>
                    </DropdownMenuInner>
                </DropdownMenu>
            </DropdownMenuWrapper>
        </header>
    )
}

export default TopBarFake