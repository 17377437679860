import { useViewport } from "../useViewport"
import Container from '../common/shared/layout/Container'
import Section from '../common/shared/layout/Section'
import TrioDisplay from '../common/shared/layout/TrioDisplay'
import DoubleDisplay from '../common/shared/layout/DoubleDisplay'

const layout = (device, images, twoOnMobile) => {
    switch (device) {
        case 'mobile':
            return (
                <Container>
                    <TrioDisplay images={twoOnMobile ? [images[0], images[1]] : [images[0]]}/>
                </Container>
            )    
        case 'tablet':
            return (
                <Container>
                    <TrioDisplay images={[images[0], images[1]]}/>
                </Container>
            ) 
        default:
            return (
                <Container ls width={9}>
                    <TrioDisplay images={images}/>
                </Container>
            )             
    }
}

const Gallery = ({images, twoOnMobile}) => {

    const { device } = useViewport()

    return (
        <Section row center>
            {layout(device, images, twoOnMobile)}
        </Section>        
    )
}

export default Gallery