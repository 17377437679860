import styled from "styled-components/macro";
import { spacing, pageWidth, device, color } from '../../style'

const SectionSpacer = styled.div`
    height: ${spacing.spacing36};
    width: 100%;
    background: ${props => props.background ? props.background : 'transparent'};
    border-bottom: ${props => props.bottomBorder ? '1px solid #f5f5f5' : null};
    @media ${device.laptop} {
        height: ${spacing.spacing32};
    }
    @media ${device.mobile} {
        height: ${spacing.spacing24};
    }
`;

export default SectionSpacer