import SlogenSection from '../../../common/shared/layout/SlogenSection'

const content = {
    slogen: `With Comets, educators have everything needed to successfully deploy world-class AI programs across their institutions.`
}

const HomeSlogen = () => {
    return (
        <SlogenSection>
            {content.slogen}
        </SlogenSection>
    )
}

export default HomeSlogen