import styled from "styled-components/macro"
import { device } from '../../style'
import Spacer from './Spacer'
import { spacing } from '../../style'
import Container from "./Container"
import Section from './Section'
import SectionHeader from "./SectionHeader"

const ResponsiveContainer = styled(Container)`
    @media ${device.tablet} {
        padding-right: 0px;
        align-items: center;
        * {
            text-align: center!important;
        }
    }
`

const Wrapper = ({image, direction, imageContent, eng, children}) => {

    const Wrapper = styled(Section)`
        width: 100%;
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: flex-start;
        @media ${device.tablet} {
            height: unset;
            flex-direction: column-reverse;
            justify-content: center;
            align-items: center;
        }
    `

    const Image = styled.div`
        background-image: url(${props => props.src});
        height: 100%;
        width: 50%;
        background-size: cover;
        -webkit-animation: myfirst 5s ; /* Chrome, Safari, Opera */
        animation: myfirst 2s;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        @media ${device.tablet} {
            width: 100%;
            height: unset;
        }
    `

    const Content = styled.div`
        display: flex;
        width: 50%;
        flex-shrink: 0;
        div {
            flex-shrink: 0;
        }
        @media ${device.tablet} {
            width: 100%;
            margin-top: unset;
            justify-content: center;
        }
    `

    const ResponsiveSpacer = styled(Spacer)`
        display: none;
        height: ${spacing.spacing24};
        @media ${device.tablet} {
            display: block;
            height: ${spacing.spacing16};
        }
    `

    return (
        <Wrapper eng={eng}>
            <Image>
                {imageContent && (
                    <Container ls width={3} indent={1}>
                        {imageContent}
                    </Container>
                )}
                {imageContent && (
                    <Container row center>
                        <Container ss width={4}>
                            {imageContent}
                        </Container>
                    </Container>
                )}                
            </Image>
            <ResponsiveSpacer/>
            <Content>
                <Container ls width={3} indent={1}>
                    {children}
                </Container>
                <Container ss width={4} centerItems={true} textAlign={'center'}>
                    {children}
                </Container>
            </Content>
        </Wrapper>        
    )
}

const GeneralSection = ({ contentLeft, eng, children }) => {
    return (
        <Wrapper imageContent={contentLeft} eng={eng}>
            <Container column eng={eng}>
                <ResponsiveContainer>
                    {children && (
                        <>
                            {children}
                        </>
                    )}
                </ResponsiveContainer>
            </Container>
        </Wrapper>
    )
}

export default GeneralSection