import styled from "styled-components/macro"
import { SectionTopic, SectionTitle, TextBody } from "../../typography"
import { spacing } from '../../style'
import Spacer from './Spacer'
import Container from './Container'
import Button from "../Button"
import IconButton from "../IconButton"

const Wrapper = styled(Container)`
    margin-top: ${props => props.hasTopic ? null : '-21px'};
`
const SectionTopicPanel = styled.div`
    text-align: ${props=> props.centered ? 'center !important' : (props.eng ? 'left' : 'right')};
`

const SectionTitlePanel = styled.div`
    animation-delay: 0.25s;
    animation-fill-mode: forwards;
    animation: fadeUp .5s;
    * {
        text-align: ${props=> props.centered ? 'center !important' : (props.eng ? 'left' : 'right')};
    }
`
const SectionDescPanel = styled(Container)`
    animation-delay: 0.35s;
    animation-fill-mode: forwards;
    animation: fadeUp .5s;
    * {
        text-align: ${props=> props.centered ? 'center !important' : (props.eng ? 'left' : 'right')};
    }
`

const SectionHeader = ({header, lineHeight, centered, eng, darkMode, shrink, titleLight}) => {

    const goTo = (path) => {
        window.location.href = path;
    }

    return (
        <Wrapper column centeredText={centered} darkMode={darkMode} hasTopic={header && header.topic} eng={eng}>
            {header && header.topic && (
                <Container column centeredText={centered}>
                    <SectionTopicPanel centered={centered} eng={eng}>
                        <SectionTopic darkMode={darkMode} shrink={shrink} eng={eng} girly={header.girly}>
                            {header.topic}
                        </SectionTopic>
                    </SectionTopicPanel>
                    <Spacer height={spacing.spacing4} />
                </Container>
            )}
            <SectionTitlePanel centered={centered} eng={eng}>
                <SectionTitle eng={eng} lineHeight={lineHeight} darkMode={darkMode} shrink={shrink} titleLight={titleLight}>
                    {header && header.title}
                </SectionTitle>
            </SectionTitlePanel>
            {header && header.desc && (
                <>
                    <Spacer height={shrink? spacing.spacing6 : spacing.spacing8}/>
                    <SectionDescPanel centered={centered} eng={eng}>
                        <TextBody darkMode={darkMode} eng={eng} shrink={shrink} >
                            {header && header.desc}
                        </TextBody>
                    </SectionDescPanel>
                    {header && header.link && (
                        <>
                        <Spacer height={spacing.spacing8}/>
                        <Container row center>
                            <IconButton eng={eng} iconBefore={header.link.eng} disabled={header.link.disabled} rotateIcon={header.link.eng} darkMode={darkMode} noBorder withLine icon={header.link.soon ? null : './shavitim-assets/left.svg'} onClick={() => goTo(header.link.path)}>
                                {header.link.caption}
                            </IconButton>
                        </Container>
                        </>
                    )}
                </>
            )}
        </Wrapper>
    )
}

export default SectionHeader