import styled from "styled-components/macro"
import { spacing, color } from "../style"

const Wrapper = styled.div`
    position: relative;
    box-sizing: border-box;
    background-clip: padding-box; /* !importanté */
    border: solid ${props => props.borderWidth}px transparent; /* !importanté */
    border-radius: ${props => props.borderRadius}px;
    :before {
        content: '';
        position: absolute;
        top: 0; 
        right: 0; 
        bottom: 0; 
        left: 0;
        z-index: -1;
        margin: -${props => props.borderWidth}px; /* !importanté */
        border-radius: inherit; /* !importanté */
        background: ${props => props.color};
    }

`

const BoxWrapper = styled(Wrapper)`
    display: flex;
    height: 100%;
    justify-content: center;
    z-index: 1;
`

const BoxBody = styled.div`
    display: flex;
    align-items: center;
    background-color: ${props => props.background};
    padding: 0px ${spacing.spacing6};
    border-radius: inherit;
    border-width: inherit;
    width: 100%;
    transition: all 0.5s;
    :hover {
        background-color: ${props => props.backgroundOnHover};
    }
`

const GradientBox = ({borderWidth, borderRadius, color, onClick, background, backgroundOnHover, children}) => {
    return (
        <BoxWrapper background={background} borderWidth={borderWidth} borderRadius={borderRadius} color={color} onClick={onClick}>
            <BoxBody background={background} backgroundOnHover={backgroundOnHover}>
                {children}
            </BoxBody>
        </BoxWrapper>
    )
}

export default GradientBox