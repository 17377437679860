import styled from "styled-components/macro"
import { spacing, color, fontSize } from "../style";

const InputBox = styled.input`
    padding: ${spacing.spacing4} ${spacing.spacing6};
    border-radius: 10px;
    width: 100%;
    height: 100%;
    border: 1px solid ${color.gray0};
    outline: none;
    font-size: ${fontSize.fontSize4};
    text-align: ${props => props.eng ? 'left' : 'right'};
`

export default InputBox