import Container from './Container'
import { useViewport } from '../../../useViewport'

const SingleDisplay = ({image, imageMobile, caption, width, eng}) => {
    const { device } = useViewport()
    return (
        <Container width={width ? width : 11} style={{position: 'relative'}}>
            <img src={device === 'mobile' || device === 'tablet' ? imageMobile: image}/>
            {caption && (device === 'desktop') && (
                <div style={{
                    position: 'absolute',
                    bottom: '40px',
                    left: '40px',
                    color: 'white',
                    fontSize: '12px',
                    maxWidth: '300px',
                    fontWeight: '500',
                    textAlign: eng ? 'left' : 'right'
                }}>
                    {caption}
                </div>
            )}
            {
                (device === 'mobile' || device === 'tablet') && (
                    <div style={{
                        textAlign: 'center',
                        color: 'black',
                        fontSize: '12px',
                        width: '100%',
                        fontWeight: '500',
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: '12px'
                    }}>
                        <span style={{width: '80%', textAlign: 'center'}}>
                            {caption}
                        </span>
                    </div>
                )
            }
        </Container>
    )
}

export default SingleDisplay