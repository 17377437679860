import styled from 'styled-components/macro'
import { color, device } from '../../style'

const GRID_BAR_WIDTH = 120;
const GRID_SPACE_WIDTH = 20;
const GRID_OFFSET = 100;

const Container = styled.div`
    width: 100%;
    display: ${props => props.ls ? 'flex' : (props.ss ? 'none' : 'flex')};
    flex-direction: ${props => {
        if (props.row) {
            return 'row'
        } else if (props.column) {
            return 'column'
        } else if (props.responsive) {
            return 'row'
        } else {
            return 'column'
        }
    }};
    justify-content: ${props => {
        if (props.right) {
            return 'flex-start'
        } else if (props.center) {
            return 'center'
        } else if (props.end) {
            return 'flex-end'
        }
    }};
    max-width: ${props => props.width ? (props.width * GRID_BAR_WIDTH) + ((props.width -1) * GRID_SPACE_WIDTH) : null}px;
    margin: 0px ${props => props.indent ? ((props.indent * GRID_BAR_WIDTH) + GRID_SPACE_WIDTH + GRID_OFFSET) : 0}px; 
    background-color: ${props => props.background ? props.background : 'none'};

    * {
        color: ${props => props.color ? props.color : (props.darkMode ? color.white : color.black)};
    }

    * {
        text-align: ${props => props.centeredText ? 'center' : (props.eng ? 'left!important' : 'right')};
    }

    @media ${device.desktopS} {
        margin: 0px ${props => props.indent ? (((props.indent-1) * GRID_BAR_WIDTH) + GRID_SPACE_WIDTH + GRID_OFFSET) : 0}px; 
    }

    @media ${device.tablet} {
        display: ${props => props.ls ? 'none' : (props.ss ? 'flex' : 'flex')};
        max-width: ${props => {
            if (props.width > 4) {
                return props.margins ? '86' : '100'
            }
            switch (props.width) {
                case 4:
                    return 86
                case 3:
                    return 62.56
                case 2: 
                    return 40.51
                case 1: 
                    return 18.2
                default:
                    return 100
            }
        }}vw;
        flex-direction: ${props => {
            if (props.row) {
                return 'row'
            } else if (props.column) {
                return 'column'
            } else if (props.responsive) {
                return 'column'
            } else {
                return 'column'
            }
        }};
        margin: 0px;
    }

    @media ${device.mobile} {

    }
`

export default Container