import styled from 'styled-components/macro'
import { fontSize, fontWeight, color } from "./style";
import { device } from './style';

export const SectionTitle = styled.h3`
    font-size: ${props => props.shrink ? fontSize.fontSize8 : fontSize.fontSize8};
    font-weight: ${props => props.titleLight ? fontWeight.extraLight: fontWeight.light};
    line-height: ${props => props.lineHeight ? props.lineHeight : '68px'};
    color: ${props => props.darkMode ? 'white !important' : 'black !important'};
    direction: ${props=>props.eng ? 'ltr' : 'rtl'};
    padding-top: 8px;
    @media ${device.mobile} {
        font-size: ${fontSize.fontSize7};
        line-height: 57.12px;
    }
`

export const TextBody = styled.p`
    font-size: ${props => props.shrink ? fontSize.fontSize4 : fontSize.fontSize4};
    font-weight: ${fontWeight.regular};
    line-height: ${props => props.shrink ? '160%' : '32px'};
    color: ${props => props.darkMode ? 'white !important' : 'black !important'};
    direction: ${props=>props.eng ? 'ltr' : 'rtl'};
`

export const SecondaryTitle = styled.p`
    font-size: ${fontSize.fontSize6};
    font-weight: ${fontWeight.regular};
    line-height: 48px;

`

export const TextBodySecondary = styled.span`
    font-size: ${fontSize.fontSize4};
    font-weight: ${fontWeight.regular};
    line-height: 32px;
    color: ${color.gray2};
    @media ${device.tablet} {
        font-size: ${fontSize.fontSize3};
        line-height: 28.8px;
    }
`

export const TextBodyBold = styled.span`
    font-size: ${fontSize.fontSize4};
    font-weight: ${fontWeight.semiBold};
    line-height: 32px;
`

export const TextBodyStrong = styled.span`
    font-size: ${fontSize.fontSize4};
    font-weight: ${fontWeight.bold};
    line-height: 32px;
`

export const TextMinor = styled.p`
    font-size: ${fontSize.fontSize3};
    font-weight: ${fontWeight.semiBold};
    line-height: 32px;
    @media ${device.tablet} {
        font-size: ${fontSize.fontSize3};
        line-height: 28.8px;
    }
    @media ${device.mobile} {
        font-size: ${fontSize.fontSize2};
        line-height: 28.8px;
    }
`

export const TextMinorLight = styled.p`
    font-size: ${fontSize.fontSize3};
    font-weight: ${fontWeight.regular};
    line-height: 32px;
`

export const TextSmall = styled.p`
    font-size: ${fontSize.fontSize2};
    font-weight: ${fontWeight.regular};
    line-height: 32px;
`

export const TextHeighlighted = styled.p`
    font-size: ${fontSize.fontSize5};
    font-weight: ${fontWeight.semiBold};
    line-height: 28.8px;
`

export const SectionTopic = styled.span`
    font-size: ${props => props.shrink ? fontSize.fontSize3 : fontSize.fontSize3};
    font-weight: ${fontWeight.semiBold};
    border-radius: 20px;
    background: ${props => props.girly ? color.spring : (props.darkMode ? color.white : color.blackGradient)};
    padding: 0px 14px;
    height: 26px;
    display: inline-block;
    line-height: 24px;
    direction: ${props=>props.eng ? 'ltr' : 'rtl'};
    color: ${props => props.darkMode ? 'black !important' : 'white !important'};
    @media ${device.tablet} {
        font-size: ${fontSize.fontSize2};
        line-height: 26px;
    }
`

export const NavBarItem = styled.span`
    font-size: ${fontSize.fontSize3};
    font-weight: ${fontWeight.regular}; 
    letter-spacing: 0.1em;   
`

export const Slogen = styled.span`
    font-size: ${fontSize.fontSize8};
    font-weight: ${fontWeight.extraLight};
    @media ${device.tablet} {
        font-size: ${fontSize.fontSize8};
    }
    @media ${device.mobile} {
        font-size: ${fontSize.fontSize7};
    }  
`

export const MobileMenuItem = styled.span`
    font-size: ${fontSize.fontSize4};
    font-weight: ${fontWeight.light};    
`

export const GradiantText = styled.span`
    background: ${props=> props.color};
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
`