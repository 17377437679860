import Helmet from 'react-helmet'

import Page from '../../common/shared/layout/Page'
import ContactSection from './sections/ContactSection'
import Footer from '../us-home/sections/Footer'

const EcoLogin = () => {

    return (
        <Page eng={true}>
            <Helmet>
                <title>Comets - Contact Us</title>
            </Helmet>
            <ContactSection/>
            <Footer/>
        </Page>  
    )
}

export default EcoLogin