import styled from "styled-components/macro"
import { useViewport } from "../../../useViewport"
import { color, device } from '../../style'
import Spacer from './Spacer'
import { spacing, TOPBAR_HEIGHT } from '../../style'
import Container from "./Container"
import Section from './Section'
import SectionHeader from "./SectionHeader"

const ResponsiveContainer = styled(Container)`
    @media ${device.tablet} {
        padding-right: 0px;
        align-items: center;
        * {
            text-align: center!important;
        }
    }
`

const ResponsiveSpacer = styled(Spacer)`
    height: ${spacing.spacing12};
    @media ${device.mobile} {
        height: ${spacing.spacing10};
    }
`

    const Wrapper2 = styled(Section)`
        width: 100%;
        height: ${props => props.first ? `calc(98vh - ${TOPBAR_HEIGHT}px)` : '98vh'};
        margin-top: ${props => props.first ? `${TOPBAR_HEIGHT}px` : null};
        display: flex;
        flex-direction: ${props => props.direction == 'left' ? 'row' : 'row-reverse'};
        justify-content: space-between;
        align-items: flex-start;
        @media ${device.tablet} {
            height: unset;
            flex-direction: ${props => props.first ? `column` : 'column-reverse'};
            justify-content: center;
            align-items: center;
        }
    `

    const Image = styled.div`
        background-image: url(${props => props.src});
        filter: ${props => props.filter ? props.filter : null};
        background-color: ${props => props.imageBackgroundColor ? props.imageBackgroundColor : null};
        height: 100%;
        width: ${props => props.withSpace ? `calc(50% - ${spacing.spacing6}/2})` : '50%'};
        background-size: cover;
        background-position: center;
        -webkit-animation: myfirst 5s ; /* Chrome, Safari, Opera */
        animation: myfirst 2s;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        @media ${device.tablet} {
            width: 100%;
            aspect-ratio: ${props => props.fitContent ? null : '1/1'};
            @supports not (aspect-ratio) { 
              padding-top: ${props => props.fitContent ? null : '100%'};
              height: ${props => props.fitContent ? null : '0'};
              position: ${props => props.fitContent ? null : 'relative'};
              overflow: ${props => props.fitContent ? null : 'hidden'};
            }
            height: unset;
        }
    `

    const Content = styled.div`
        display: flex;
        align-items: center;
        background-color: ${props => props.background ? props.background : null};
        height: 100%;
        width: ${props => props.withSpace ? `calc(50% - ${spacing.spacing6}/2)` : '50%'};
        flex-shrink: 0;
        div {
            flex-shrink: 0;
        }
        @media ${device.tablet} {
            width: 100%;
            margin-top: unset;
            justify-content: center;
        }
    `

    const ResponsiveSpacer2 = styled(Spacer)`
        display: ${props => props.withSpace ? 'block' : 'none'};
        width: ${props => props.withSpace ? spacing.spacing6 : null};
        height: ${spacing.spacing24};
        @media ${device.tablet} {
            display: block;
            height: ${spacing.spacing16};
        }
    `

const Wrapper = ({image, direction, filter, imageContent, imageBackgroundColor, fitContent, background, withSpace, first, children}) => {

    const { device: dv } = useViewport()

    return (
        <Wrapper2 direction={direction} first={first}>
            <Image src={image} filter={filter} fitContent={fitContent} imageBackgroundColor={imageBackgroundColor}>
                {imageContent && (
                    <Container ls width={3} indent={1}>
                        {imageContent}
                    </Container>                  
                )}
                {imageContent && (
                    <Container row center>
                        <Container ss centeredText width={4}>
                            {imageContent}
                        </Container>  
                    </Container>                  
                )}                
            </Image>
            <ResponsiveSpacer2 withSpace={withSpace}/>
            <Content background={background} withSpace={withSpace}>
                <Container ls width={3} indent={1}>
                    {children}
                </Container>
                <Container ss width={dv == 'mobile' ? 4 : 3} centerItems={true} textAlign={'center'}>
                    {children}
                </Container>
            </Content>
        </Wrapper2>        
    )
}

const ImageSection = ({ image, header, centerHeader, first, direction, imageContent, imageBackgroundColor, fitContent, eng, background, withSpace, filter, children }) => {
    return (
        <Wrapper image={image} filter={filter} imageBackgroundColor={imageBackgroundColor} direction={direction} first={first} imageContent={imageContent} fitContent={fitContent} withSpace={withSpace} background={background}>
            <Container column>
                <ResponsiveContainer>
                    {header && <SectionHeader header={header} centered={centerHeader} eng={eng}/>}
                    {header && children && <ResponsiveSpacer/>}
                    {children && (
                        <>
                            {children}
                        </>
                    )}
                </ResponsiveContainer>
            </Container>
        </Wrapper>
    )
}

export default ImageSection