import styled from "styled-components/macro"
import Spacer from "./layout/Spacer"
import { fontSize, fontWeight, color } from "../style"
import Button from './Button'

const ButtonBody = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: row;
    width: 100%;
`

const ButtonCaption = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: ${props => props.eng ? 'column-reverse' : 'column'};
    position: relative;
    border-bottom: ${props => props.withLine ? (props.darkMode ? '1px solid white' : '1px solid black') : null};
    border-bottom-color: ${props => props.disabled ? '#eee' : (props.darkMode ? 'white' : 'black')};
    padding-bottom: ${props => props.withLine ? '13px' : null};
    transition: padding-bottom 0.25s; 
    :hover {
        padding-bottom: ${props => (props.withLine && !props.disabled) ? '18px' : null};
    }
    span {
        font-size: ${fontSize.fontSize3};
        font-weight: ${fontWeight.regular};
        text-align: right;
    }
`

const ButtonIcon = styled.div`
    display: flex;
    justify-content: center;
    position: absolute;
    left: ${props => props.iconBefore ? null : '-20px'};
    right: ${props => props.iconBefore ? '-20px' : null};
    top: -6px;
    img {
        width: 15px;
        filter: ${props => (props.darkMode || props.iconInvert) ? null : 'invert(1)'};
        transform: ${props => props.rotateIcon ? 'rotate(180deg)' : null};
    }
`

const IconButton = ({expanded, icon, inline, withLine, filled, eng, darkMode, disabled, width, onClick, noBorder, iconBefore, height, rotateIcon, iconInvert, children}) => {
    return (
        <Button noPadding expanded={expanded} filled={filled} height={inline ? '0px' : height} darkMode={darkMode} disabled={disabled} width={width} onClick={onClick} noBorder={noBorder}>
            <ButtonBody>
                <ButtonCaption eng={eng} withLine={withLine} disabled={disabled} darkMode={darkMode}>
                    <span>{children}</span>
                    {icon && (
                        <ButtonIcon iconBefore={eng ? !iconBefore : iconBefore} iconInvert={iconInvert} darkMode={darkMode} rotateIcon={eng ? !rotateIcon : rotateIcon}>
                            <img src={icon}/>
                        </ButtonIcon>
                    )}                    
                </ButtonCaption>
            </ButtonBody>
        </Button>
    )
}

export default IconButton