import Button from '../../../common/shared/Button'
import InputBox from '../../../common/shared/InputBox'
import ImageSection from '../../../common/shared/layout/ImageSection'
import Spacer from '../../../common/shared/layout/Spacer'
import { SectionTitle } from '../../../common/typography'
import ContactForm from '../../../components/ContactForm'

const image = 'https://res.cloudinary.com/dfdk4g2pj/image/upload/v1719687720/image_7_stxo0g.png'

const Welcome = () => {
    return (
        <ImageSection image={image} first={true} eng={true}>
            <div dir='ltr'>
                <SectionTitle eng={true} style={{textAlign:'left'}}>
                    Welcome to EcoSphere
                </SectionTitle>
                <Spacer height={'60px'}/>   
                <InputBox eng placeholder='Your company email address'/>
                <Spacer height={'20px'}/>
                <Button expanded filled disabled> Login </Button>
            </div>
        </ImageSection>
    )
}

export default Welcome
