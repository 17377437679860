import ContentSection from '../common/shared/layout/ContentSection'
import Container from '../common/shared/layout/Container'
import { TextBody } from '../common/typography'
import { useViewport } from '../useViewport'

const AboutSection = ({ header, eng, children }) => {
    const { device } = useViewport()
    return (
        <ContentSection header={header} wideHeader={true} eng={eng}>
            <Container column>
                <TextBody eng={eng} style={{textAlign:(device === 'tablet' || device === 'mobile') ? 'center': 'right'}}>
                    {children}
                </TextBody>
            </Container>
        </ContentSection>
    )
}

export default AboutSection