import { useViewport } from '../../../useViewport'
import { spacing } from '../../style'
import Container from './Container'
import SectionHeader from './SectionHeader'
import SectionSpacer from './SectionSpacer'
import Spacer from './Spacer'

const FocusSection = ({children, eng, background, header}) => {

    const { device } = useViewport()

    return (
        <Container>
            {background && (<SectionSpacer background={background}/>)}
            <Container background={background}>
                <Container row center>
                    <Container centeredText width={(device === 'mobile') ? 4 : (device == 'tablet' ? 3 : 5)}>
                        <SectionHeader eng={eng} centered={true} header={header}/>
                    </Container>
                </Container>
                <Spacer height={spacing.spacing24}/>
                <Container>
                    {children}
                </Container>            
            </Container>
        </Container>
    )
}

export default FocusSection