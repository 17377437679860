import styled from "styled-components/macro"
import { device } from '../../style'
import Container from "./Container"

const Section = styled(Container)`
    max-width: 1920px;
    max-height: 1080px;
    @media ${device.tablet} {
        max-height: unset;
        max-width: unset;
    }
`

export default Section