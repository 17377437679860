import { useViewport } from '../../../useViewport'
import styled from "styled-components/macro"
import ImageSection from '../../../common/shared/layout/ImageSection'
import SectionHeader from '../../../common/shared/layout/SectionHeader'
import ContactForm from '../../../components/ContactForm'
import SectionSpacer from '../../../common/shared/layout/SectionSpacer'

const header = {
    topic: 'Contact Us',
    title: `Let's Connect!`
}

const image = 'https://res.cloudinary.com/dfdk4g2pj/image/upload/v1719846668/elirannatan_3d_model_minimal_wireframes_on_a_black_white_ai_vis_c1914ce0-620d-46f8-8684-ff57791e4762_znvrw1.png'

const ContactUs2 = () => {

    return (
        <ImageSection header={header} centerHeader image={image} eng={true}>
            <ContactForm eng={true}/>
        </ImageSection>                 
    ) 
}

export default ContactUs2